import { useCallback, useEffect, useState } from 'react';
import { useDataProvider, useVersion } from 'react-admin';

const CACHE_DURATION = process.env.REACT_APP_CACHE_DURATION;

const useProfile = () => {
  const dataProvider = useDataProvider();
  const version = useVersion();

  const [profile, setProfile] = useState<Record<string, any>>();
  const [loading, setLoading] = useState<boolean>(true);

  // Add cache management
  const cacheKey = 'profileCache';

  const getProfile = useCallback(async () => {
    // Check cache first
    const cachedData = localStorage.getItem(cacheKey);

    const { timestamp } = JSON.parse(cachedData || '{}');
    const isValid = Date.now() - timestamp < Number(CACHE_DURATION);

    const { data: profile } = await dataProvider.getOne('profile', {
      id: 'profile',
      bypassCache: !isValid,
    });

    // Store in cache
    localStorage.setItem(
      cacheKey,
      JSON.stringify({
        timestamp: Date.now(),
      })
    );

    setProfile(profile);
    setLoading(false);
    return profile;
  }, [dataProvider]);

  useEffect(() => {
    getProfile();
  }, [dataProvider, getProfile, version]);

  return { profile, getProfile, loading };
};

export default useProfile;
